import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Insurance App Template | Insurance App UI Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/insurance-mobile-app/"
    metaDescription="Bring your insurance app design to life with Excellion, our brand-new insurance app design template for mobile devices. Try now with Uizard Pro."
    description="
    h2:Introducing Excellion, an insurance app design template for mobile
    <br/>
    Bring your insurance app design to life with Excellion, our brand-new insurance app design template for mobile devices. Packed with everything you could possibly need for your project, Excellion comes with all the necessary UI and UX considerations built in. <a:https://uizard.io/templates/mobile-app-templates/>Mobile app design</a> has never been so easy.
    <br/>
    h3:Perfect your insurance app UI design on your own or with your team
    <br/>
    Work solo or collaborate with your team with Uizard's smart sharing features, the possibilities are endless. Creating <a:https://uizard.io/mockups/>UI mockups</a> collaboratively with Uizard is easy. Simply share your insurance app project via email and invite team members to comment and design together in real-time.
    <br/>
    h3:Customize like never before
    <br/>
    Whether you are designing an insurance app for life insurance or you're working on a car insurance app design, our <a:https://uizard.io/templates/mobile-app-templates/>app template</a> is endlessly customizable, helping you to bring your own vision to life with ease. Simply sign up for Uizard Pro, open the template, and design like never before.
    "
    pages={[
      "A stunning insurance app landing screen mockup",
      "'Create an account' user flow mockup",
      "Insurance category search functionality mockup",
      "Policy services management screen mockup",
    ]}
    projectCode="VRzLJ7Xv3WIR9yxQ1zoV"
    img1={data.image1.childImageSharp}
    img1alt="insurance app design template cover screen"
    img2={data.image2.childImageSharp}
    img2alt="insurance app design template item mennu screen"
    img3={data.image3.childImageSharp}
    img3alt="insurance app design template item map screen"
    img4={data.image4.childImageSharp}
    img4alt="insurance app design template policy screen"
    img5={data.image5.childImageSharp}
    img5alt="insurance app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/insurance-mobile-app/insurance-app-mobile-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/insurance-mobile-app/insurance-app-mobile-menu.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/insurance-mobile-app/insurance-app-mobile-map.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/insurance-mobile-app/insurance-app-mobile-products.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/insurance-mobile-app/insurance-app-mobile-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
